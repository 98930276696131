import React from "react"
import Header from "../components/header"
import Footer from "../components/footer-cannabis"
import { Helmet } from "react-helmet"
import "../assets/index.css"
import ogImage from "../images/og_image.png"
import twitterImage from "../images/twitter_port.png"
import { Location } from '@reach/router'

function getTitle(props) {
    return props.title;
}
const Layout = ({ children, location, ...props }) => (
    <>
       <Helmet>
            <meta charSet="utf-8" />
            <title>{getTitle(props)}</title>
            <link rel="canonical" href="https://laddpartners.com" />
            <meta name="description" content="Full Service Consulting for FINANCIALFORCE & SALESFORCE -  Implementation, Support, Custom Development and System Health Checks" />
            <meta property="og:url" content="https://laddpartners.com"/>
            <meta property="og:title" content="Ladd Partners" />
            <meta property="og:description" content="Full Service Consulting for FINANCIALFORCE & SALESFORCE -  Implementation, Support, Custom Development and System Health Checks" />
            <meta property="og:image" content={`https://laddpartners.com${ogImage}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Ladd Partners" />
            <meta name="twitter:description" content="Full Service Consulting for FINANCIALFORCE & SALESFORCE" />
            <meta name="twitter:url" content="https://twitter.com/LaddPartners" />
            <meta name="twitter:image" content={`https://laddpartners.com${twitterImage}`}/>
        </Helmet>

        <main>
            <Location>
                {({ location }) => {
                    return  <><Header location={{ location }} /></>
                }}
            </Location>
            {children}
            <Footer/>
        </main>
    </>
)

export default Layout